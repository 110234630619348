import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Cookies from "universal-cookie";
import Home from "./pages/Home";
import Users from "./pages/users/Users";
import Notifications from "./pages/Notifications";
import CreateCandidate from "./pages/candidates/CreateCandidate";
import AllCanditates from "./pages/candidates/AllCanditates";
import NotFound from "./pages/NotFound";
import Layout from "./components/Layout";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import CreateUser from "./pages/users/CreateUser";
import Sharepoint from "./components/Sharepoint";
import ViewUpdateCanditate from "./pages/candidates/ViewUpdateCandidate";

const Routes = () => {
  const cookie = new Cookies();
  const user = cookie.get("USERDATA");

  return (
    <BrowserRouter>
      <Route
        render={(props) => {
          return user ? (
            <Layout {...props} user={user}>
              <Switch>
                {/* <Redirect from="/login" exact to="/login" /> */}
                <Route path="/" exact component={Home} />
                <Route path="/home" exact component={Home} />
                <Route path="/users" exact component={Users} />
                {user.roles[0] === "ROLE_ADMIN" && (
                  <Route path="/create-user" exact component={CreateUser} />
                )}
                {user.roles[0] === "ROLE_ADMIN" && (
                  <Route path="/user/:id" exact component={CreateUser} />
                )}
                <Route
                  path="/candidate/:id"
                  exact
                  component={ViewUpdateCanditate}
                />
                <Route
                  path="/create-candidate"
                  exact
                  component={CreateCandidate}
                />
                <Route path="/all-candidates" exact component={AllCanditates} />
                <Route path="/notifications" component={Notifications} />
                <Route path="/sharepoint" exact component={Sharepoint} />
                <Route component={NotFound} />
              </Switch>
            </Layout>
          ) : (
            <Switch>
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/login" exact component={Login} />
              <Redirect from="*" exact to="/login" />
            </Switch>
          );
        }}
      />
    </BrowserRouter>
  );
};

export default Routes;
