import React from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

import { optionsLanguages } from "./dropdownOptions";
import { filterOptions } from "./inputsOptions";
import { customStylesSelect } from "./customStyle";
import { default as ReactSelect } from "react-select";
import "../users/users.css";

const SubheaderTable = ({
  handleFilter,
  sources,
  statuses,
  paramsObj,
  toggleFilters,
  setToggleFilters,
  filters,
  setFilters,
}) => {
  const onStatusChange = (selected) => {
    if (selected) {
      setFilters((filters) => ({ ...filters, status: selected.id }));
    } else {
      setFilters((filters) => ({ ...filters, status: null }));
    }
  };
  const onLanguageChange = (selected) => {
    if (selected) {
      let languages = "";
      selected.forEach((select) => {
        languages += select.id + ";";
      });
      setFilters((filters) => ({ ...filters, language: languages }));
    } else {
      setFilters((filters) => ({ ...filters, language: null }));
    }
  };
  const onSourceChange = (selected) => {
    if (selected) {
      setFilters((filters) => ({ ...filters, source: selected.id }));
    } else {
      setFilters((filters) => ({ ...filters, source: null }));
    }
  };

  const toggle = () => {
    setToggleFilters(!toggleFilters);
  };

  const multiSelectPopulate = (options, values) => {
    if (!values) {
      return [];
    }
    let valuesArray = values.split(";");
    let selectOptions = [];
    options.forEach((option) => {
      if (valuesArray.includes(option.id)) {
        selectOptions.push(option);
      }
    });

    return selectOptions;
  };

  const setField = (name, value) => {
    setFilters((filters) => ({ ...filters, [name]: value }));
  };

  return (
    <div>
      <div className="open-filters-div">
        <button className="btn-action open-filters" onClick={toggle}>
          {!toggleFilters ? "Open" : "Close"} filters
          {!toggleFilters ? (
            <BsArrowDown className="open-filters-arrow" />
          ) : (
            <BsArrowUp className="open-filters-arrow" />
          )}
        </button>
      </div>
      <form>
        <div
          className={`wrapper-subheader_candidates ${
            toggleFilters ? "filters-opened" : ""
          }`}
        >
          {filterOptions.map((option) => {
            return option.type === "text" ? (
              <div key={option.name} className="select-wrapper">
                <input
                  key={option}
                  type="text"
                  placeholder={`Filter by ${option.name}`}
                  className="search-box__candidates"
                  onChange={(e) => setField(option.name, e.target.value)}
                  defaultValue={paramsObj[option.name]}
                />
              </div>
            ) : option.type === "date" ? (
              <div key={option.name} className="select-wrapper">
                <input
                  key={option}
                  type="text"
                  id={option}
                  onFocus={(e) => {
                    e.target.type = "date";
                  }}
                  placeholder={"Filter by " + option.name}
                  className="search-box__candidates"
                  defaultValue={paramsObj[option.name]}
                  onChange={(e) => setField(option.name, e.target.value)}
                />
              </div>
            ) : (
              <div key={option.name} className="select-wrapper">
                <ReactSelect
                  key={option.name}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={
                    option.name === "language"
                      ? optionsLanguages
                      : option.name === "status"
                      ? statuses
                      : sources
                  }
                  menuPortalTarget={document.body}
                  styles={customStylesSelect}
                  placeholder={"Filter by " + option.name}
                  isClearable
                  isMulti={option.name === "language" ? true : false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  defaultValue={
                    option.name === "language"
                      ? multiSelectPopulate(
                          optionsLanguages,
                          paramsObj.language
                        )
                      : option.name === "status"
                      ? statuses.filter(
                          (option) => option.id === Number(paramsObj.status)
                        )
                      : sources.filter(
                          (option) => option.id === Number(paramsObj.source)
                        )
                  }
                  onChange={
                    option.name === "language"
                      ? onLanguageChange
                      : option.name === "status"
                      ? onStatusChange
                      : onSourceChange
                  }
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgray",
                      primary: "#1b2330",
                    },
                  })}
                />
              </div>
            );
          })}
          <div className="select-wrapper">
            <button
              key="filter"
              type="submit"
              className="btn-action filter_candidates"
              onClick={handleFilter}
            >
              FILTER
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SubheaderTable;
