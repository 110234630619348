// esentials
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
//stylesheet
import styles from "./candidates.module.css";
// icons
import * as Icons from "react-icons/md";
import { IoCloudUploadOutline, IoCloudDoneOutline } from "react-icons/io5";
import { BiDollar } from "react-icons/bi";
import { GoLocation } from "react-icons/go";
import { FaCommentDots } from "react-icons/fa";
// helpers
import {
  optionsLanguages,
  optionsInterview,
  optionsFeedback,
} from "./dropdownOptions";
import { fieldsTexarea } from "./inputsOptions";
import { Logout } from "../../helpers/logout";
// notifications
import { store } from "react-notifications-component";
import {
  notificationError,
  notificationSucces,
} from "../../helpers/notifications";
// components
import Loader from "react-loader-spinner";
import NotFound from "../NotFound";
import InputText from "./components/InputText";
import InputSelect from "./components/InputSelect";

const ViewUpdateCanditate = (props) => {
  const id = Number(props?.match?.params?.id);
  const urlParams = props.location.search;
  const [candidate, setCandidate] = useState({});
  const [loading, setLoading] = useState(true);
  const [candidateUpdate, setCandidateUpdate] = useState({
    name: candidate.name,
    email: candidate.email,
    contactedDate: candidate.contactedDate,
    phone: candidate.phone,
    comments: candidate.comments,
    project: candidate.project,
    language: candidate.language,
    salaryExpectations: candidate.salaryExpectations,
    interviewDate: candidate.interviewDate,
    status: candidate.status,
    source: candidate.source,
    recruiter: candidate.recruiter,
    dateOfJoining: candidate.dateOfJoining,
    feedback: candidate.feedback,
    interviewType: candidate.interviewType,
    keywords: candidate.keywords,
    feedbackLanguage: candidate.feedbackLanguage,
    role: candidate.role,
    statusDetails: candidate.statusDetails,
    sourceDetails: candidate.sourceDetails,
    location: candidate.location,
    cay: null,
  });

  // file upload
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  // get Statuses & sources
  const [statuses, setStatuses] = useState({});
  const [sources, setSources] = useState({});
  const [statusesReady, setStatusesReady] = useState(true);
  const [sourcesReady, setSourcesReady] = useState(true);

  useEffect(() => {
    const getSources = async () => {
      await axios
        .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/sources`)
        .then((res) => {
          setSources(
            res?.data?.results.sort((a, b) =>
              a.orderId > b.orderId ? 1 : b.orderId > a.orderId ? -1 : 0
            )
          );
          setSourcesReady(false);
        })
        .catch((err) => console.log(err));
    };
    const getStatuses = async () => {
      await axios
        .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/statuses`)
        .then((res) => {
          setStatuses(
            res?.data?.results.sort((a, b) =>
              a.orderId > b.orderId ? 1 : b.orderId > a.orderId ? -1 : 0
            )
          );
          setStatusesReady(false);
        })
        .catch((err) => console.log(err));
    };
    const getCandidate = async () => {
      await axios
        .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/candidate/${id}`)
        .then((res) => {
          setCandidate(res?.data.results[0]);
          setLoading(false);
        })
        .catch((err) => console.log(err));
      // setLoading(false);
    };
    getCandidate();
    getSources();
    getStatuses();
  }, [id]);

  let history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .patch(`${process.env.REACT_APP_DEV_API_ROUTE}/api/candidate/${id}`, {
        ...candidateUpdate,
      })
      .then((res) => {
        if (isFilePicked && selectedFile) {
          const formdata = new FormData();
          formdata.append("file", selectedFile, selectedFile.name);
          formdata.append("type", "1");
          formdata.append("candidate_id", candidate.id);
          axios
            .post(
              `${process.env.REACT_APP_DEV_API_ROUTE}/api/candidate_file`,
              formdata
            )
            .then((res) => {
              notificationSucces.message = "Candidate successfully updated!";
              store.addNotification(notificationSucces);
              history.push({ pathname: "/all-candidates", search: urlParams });
            })
            .catch((err) => {
              if (err?.response?.data?.results) {
                notificationError.message =
                  err?.response?.data?.results[0]?.message;
                store.addNotification(notificationError);
              } else {
                notificationError.message = err.response.data.title;
                store.addNotification(notificationError);
              }
            });
        } else {
          notificationSucces.message = "Candidate successfully updated!";
          store.addNotification(notificationSucces);
          history.push({ pathname: "/all-candidates", search: urlParams });
        }
      })
      .catch((err) => {
        if (
          err.response?.data?.code === 401 ||
          err.response?.data?.code === 403
        ) {
          Logout();
        } else if (err.response?.data?.results) {
          err.response.data.results.forEach((error) => {
            document.getElementById("error-" + error.field).innerHTML =
              error.message;
            document.getElementById(error.field).classList.add(styles.error);
          });
          notificationError.message =
            "You have some errors! Please, check input fields!";
          store.addNotification(notificationError);
        } else {
          notificationError.message =
            "Something went wrong... Please try again!";
          store.addNotification(notificationError);
        }
      });
  };
  const cookie = new Cookies();
  const user = cookie.get("USERDATA");

  const setField = (name, value) => {
    setCandidateUpdate((candidateUpdate) => ({
      ...candidateUpdate,
      [name]: value,
    }));
  };

  const onStatusChange = (selected) => {
    setCandidateUpdate((candidateUpdate) => ({
      ...candidateUpdate,
      status: selected.id,
    }));
  };
  const onLanguageChange = (selected) => {
    let languages = "";
    selected.forEach((select) => {
      languages += select.id + ";";
    });
    setCandidateUpdate((candidateUpdate) => ({
      ...candidateUpdate,
      language: languages,
    }));
  };
  const onSourceChange = (selected) => {
    setCandidateUpdate((candidateUpdate) => ({
      ...candidateUpdate,
      source: selected.id,
    }));
  };
  const onInterviewChange = (selected) => {
    setCandidateUpdate((candidateUpdate) => ({
      ...candidateUpdate,
      interviewType: selected.id,
    }));
  };
  const onFeedbackLanguageChange = (selected) => {
    setCandidateUpdate((candidateUpdate) => ({
      ...candidateUpdate,
      feedbackLanguage: selected.id,
    }));
  };

  const multiSelectPopulate = (options, values) => {
    if (!values) {
      return [];
    }
    let valuesArray = values.split(";");
    let selectOptions = [];
    options.forEach((option) => {
      if (valuesArray.includes(option.id)) {
        selectOptions.push(option);
      }
    });

    return selectOptions;
  };

  const formatDateTime = (date) => {
    if (!date) {
      return null;
    }
    const d = date.split("+");
    return d[0];
  };
  const formatDate = (date) => {
    if (date) {
      const d = date.split("T");
      return d[0];
    }
  };

  const formFields = [
    { name: "name", type: "text", title: "Name", restricted: false },
    { name: "phone", type: "text", title: "Phone Number", restricted: false },
    { name: "email", type: "text", title: "Email", restricted: false },
    { name: "recruiter", type: "text", title: "Recruiter", restricted: false },

    {
      name: "status",
      type: "select",
      title: "Status",
      options: statuses,
      onChange: onStatusChange,
    },

    {
      name: "statusDetails",
      type: "text",
      title: "Status Details",
      restricted: false,
    },
    {
      name: "source",
      type: "select",
      title: "Source",
      options: sources,
      onChange: onSourceChange,
    },
    {
      name: "sourceDetails",
      type: "text",
      title: "Source Details",
      restricted: false,
    },
    { name: "project", type: "text", title: "Project", restricted: false },

    {
      name: "language",
      type: "select",
      title: "Languages",
      options: optionsLanguages,
      onChange: onLanguageChange,
      defaultValue: multiSelectPopulate(optionsLanguages, candidate.language),
    },

    {
      name: "feedbackLanguage",
      type: "select",
      title: "Feedback Language",
      options: optionsFeedback,
      onChange: onFeedbackLanguageChange,
      defaultValue: optionsFeedback.filter(
        (option) => option.id === candidate.feedbackLanguage
      ),
    },

    {
      name: "interviewType",
      type: "select",
      title: "Interview Type",
      options: optionsInterview,
      onChange: onInterviewChange,
      defaultValue: optionsInterview.filter(
        (option) => option.id === candidate.interviewType
      ),
    },
    { name: "location", type: "text", title: "Location", restricted: false },
    { name: "role", type: "text", title: "Role", restricted: false },
    {
      name: "createdAt",
      type: "date",
      title: "Created At",
      restricted: false,
      disabled: true,
      defaultValue: formatDate(candidate.createdAt),
    },

    {
      name: "contactedDate",
      type: "date",
      title: "Contacted Date",
      defaultValue: formatDate(candidate.contactedDate),
      restricted: false,
    },
    {
      name: "interviewDate",
      type: "datetime-local",
      title: "Interview Date",
      restricted: false,
    },
    {
      name: "dateOfJoining",
      type: "date",
      title: "Date of Joining",
      defaultValue: formatDate(candidate.dateOfJoining),
      restricted: false,
    },
    {
      name: "lastDay",
      type: "date",
      title: "Last Day",
      defaultValue: formatDate(candidate.lastDay),
      restricted: false,
    },

    {
      name: "salaryExpectations",
      type: "text",
      title: "Salary Expectations",
      restricted: user.roles[0] === "ROLE_ADMIN" ? false : true,
    },
  ];

  if (loading || statusesReady || sourcesReady) {
    return (
      <div className="spinner">
        <Loader
          type="ThreeDots"
          color="#1b2330"
          height={100}
          width={100}
          timeout={4000}
        />
      </div>
    );
  }
  if (!loading && Object.keys(candidate).length === 0) {
    return <NotFound />;
  }
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.text_center}>Update Candidate's Profile</h1>
      </header>
      <form className={styles.form}>
        <div className={styles.container_fluid} style={{ flexWrap: "wrap" }}>
          {formFields.map((option) => {
            return option.type === "select" ? (
              <div key={option.name} className={styles.input_wrapper}>
                <label className={styles.label}>
                  {option.name === "language" ||
                  option.name === "feedbackLanguage" ? (
                    <Icons.MdLanguage className={styles.icon} />
                  ) : (
                    <Icons.MdAssignment className={styles.icon} />
                  )}
                  {option.title}
                </label>
                <InputSelect
                  id={option.name}
                  className={styles.input_field}
                  options={option.options}
                  isMulti={option.name === "language" ? true : false}
                  defaultValue={
                    option.name === "status"
                      ? statuses.filter(
                          (option) => option.id === candidate.status
                        )
                      : option.name === "source"
                      ? sources.filter(
                          (option) => option.id === candidate.source
                        )
                      : option.defaultValue
                  }
                  onChange={option.onChange}
                />
                <p
                  id={"error-" + option.name}
                  className={styles.error_validation}
                ></p>
              </div>
            ) : (
              option.restricted === false && (
                <div key={option.name} className={styles.input_wrapper}>
                  <label className={styles.label}>
                    {option.name === "name" ? (
                      <Icons.MdPerson className={styles.icon} />
                    ) : option.name === "email" ? (
                      <Icons.MdEmail className={styles.icon} />
                    ) : option.name === "salaryExpectations" ? (
                      <BiDollar className={styles.icon} />
                    ) : option.type === "date" ||
                      option.type === "datetime-local" ? (
                      <Icons.MdToday className={styles.icon} />
                    ) : option.name === "location" ? (
                      <GoLocation className={styles.icon} />
                    ) : option.name === "phone" ? (
                      <Icons.MdPhone className={styles.icon} />
                    ) : (
                      <Icons.MdAssignment className={styles.icon} />
                    )}
                    {option.title}
                  </label>
                  <InputText
                    id={option.name}
                    className={styles.input_field}
                    type={option.type}
                    onChange={(e) => setField(option.name, e.target.value)}
                    defaultValue={
                      option.type === "datetime-local"
                        ? formatDateTime(candidate.interviewDate)
                        : option.type === "date"
                        ? option.defaultValue
                        : candidate[option.name]
                    }
                    disabled={option.disabled}
                  />
                  <p
                    id={"error-" + option.name}
                    className={styles.error_validation}
                  ></p>
                </div>
              )
            );
          })}
          {user.roles[0] === "ROLE_ADMIN" && (
            <div className={styles.input_wrapper}></div>
          )}
        </div>
        <div className={styles.container_fluid}>
          {fieldsTexarea.map((option) => {
            return (
              <div key={option.name} className={styles.input_wrapper}>
                <label className={styles.label}>
                  <FaCommentDots className={styles.icon} />
                  {option.title}
                </label>
                <textarea
                  className={styles.input_field}
                  id={option.name}
                  style={{ height: "6rem" }}
                  onChange={(e) => setField(option.name, e.target.value)}
                  defaultValue={candidate[option.name]}
                />
                <p
                  id={"error-" + option.name}
                  className={styles.error_validation}
                ></p>
              </div>
            );
          })}
        </div>
        <div className={`${styles.container_fluid_upload}`}>
          <label htmlFor="file-upload" className={styles.upload_label}>
            {!isFilePicked ? (
              <IoCloudUploadOutline className={styles.icon} />
            ) : (
              <IoCloudDoneOutline className={styles.icon} />
            )}
            {candidate?.candidateFiles[0]
              ? "Upload Another Resume"
              : "Upload Resume"}
          </label>
          <input
            encType="multipart/form-data"
            id="file-upload"
            type="file"
            onChange={changeHandler}
            accept=".pdf, .jpg, .jpeg, .doc, .docx, .png"
          />
          <p className={styles.upload_file__name}>
            {candidate.candidateFiles[0] && !isFilePicked && !selectedFile ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_DEV_API_ROUTE}${candidate.candidateFiles[0].link}`}
              >
                {candidate.candidateFiles[0].name}
              </a>
            ) : isFilePicked && selectedFile ? (
              selectedFile.name
            ) : (
              "No File Chosen"
            )}
          </p>
        </div>

        <div className={styles.btn_wrapper}>
          <button className={styles.btn} onClick={handleSubmit}>
            Update Profile
          </button>
        </div>
      </form>
    </div>
  );
};

export default ViewUpdateCanditate;
