export const customStyle = {
  table: {
    style: {
      padding: "0rem 1rem",
      margin: "auto",
    },
  },
  tableWrapper: {
    style: {
      display: "table",
    },
  },
  header: {
    style: {
      fontSize: "2rem",
      textAlign: "center",
      color: "white",
      backgroundColor: "#1b2330",
      borderRadius: "6px 6px 0 0",
    },
  },
  subHeader: {
    style: {
      height: "fit-content",
      width: "100%",
      padding: "0",
    },
  },
  headCells: {
    style: {
      fontSize: "1rem",
      padding: "0.9rem",
    },
  },
  columns: {
    style: {
      width: "3rem",
    },
  },
  rows: {
    style: {
      fontSize: "0.8rem",
    },
  },
};

export const customStylesUserSelect = {
  container: (provided) => ({
    ...provided,
    width: "85%",
    height: "100%",
    margin: "0.2rem auto 1.2rem auto",
    justifyContent: "flex-start",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "100%",
    width: "100%",
    fontSize: "16px",
    borderColor: state.isFocused ? "rgb(34, 211, 238)" : "rgb(238, 238, 238)",
    border: state.isFocused ? "1px" : "",
    borderRadius: "6px",
    color: "rgb(17, 24, 39)",
    transition: "all 0.2s ease",
    backgroundColor: "#fff",
  }),
};
