export const fieldsTexarea = [
  { name: "feedback", type: "textarea", title: "Feedback" },
  { name: "keywords", type: "textarea", title: "Keywords" },
  { name: "comments", type: "textarea", title: "Comments" },
];

export const filterOptions = [
  { name: "name", type: "text" },
  { name: "email", type: "text" },
  { name: "phone", type: "text" },
  {
    name: "status",
    type: "select",
  },
  { name: "statusDetails", type: "text" },
  {
    name: "recruiter",
    type: "text",
  },
  {
    name: "source",
    type: "select",
  },
  { name: "sourceDetails", type: "text" },
  {
    name: "language",
    type: "select",
  },
  { name: "project", type: "text" },
  {
    name: "interviewDate",
    type: "date",
  },
  { name: "dateOfJoining", type: "date" },
  { name: "location", type: "text" },
  { name: "role", type: "text" },
  { name: "salaryExpectations", type: "text" },
  { name: "feedback", type: "text" },
  { name: "keywords", type: "text" },
  { name: "comments", type: "text" },
  { name: "contactedDate", type: "date" },
];
