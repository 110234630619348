import React from "react";
import styles from "../candidates.module.css";

const InputText = (props) => {
  return (
    <>
      <input {...props} className={styles.input_field} />
    </>
  );
};

export default InputText;
