import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useHistory, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { customStyleTable } from "./customStyle";

const TableCandidates = ({
  data,
  subheader,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleSort,
  urlParams,
}) => {
  // get statuses
  const [statuses, setStatuses] = useState({});
  const [statusesReady, setStatusesReady] = useState(true);
  useEffect(() => {
    const getStatuses = async () => {
      await axios
        .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/statuses`)
        .then((res) => {
          setStatuses(res?.data?.results);
          setStatusesReady(false);
        })
        .catch((err) => console.log(err));
    };
    getStatuses();
  }, []);

  const filterStatuses = (id) => {
    const status = statuses.filter((option) => option.id === id);
    if (status.length > 0) {
      return status[0].name;
    }
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => [row.id],
      sortable: true,
      cell: (row) => (
        <div>
          <div style={{ cursor: "pointer" }} data-tag="allowRowEvents">
            {row.id}
          </div>
          {row.summary}
        </div>
      ),
      width: "5rem",
    },
    {
      name: "CV",

      cell: (row) => (
        <div>
          <div
            data-tag="allowRowEvents"
            style={
              row.candidateFiles.length === 0
                ? {
                    backgroundColor: "red",
                    color: "white",
                    width: "2rem",
                    textAlign: "center",
                    cursor: "pointer",
                  }
                : {
                    backgroundColor: "green",
                    pading: "1rem",
                    color: "white",
                    width: "2rem",
                    textAlign: "center",
                    cursor: "pointer",
                  }
            }
          >
            {row.candidateFiles.length === 0 ? "NO" : "YES"}
          </div>
        </div>
      ),
      width: "3.5rem",
    },
    {
      name: "Name",
      selector: (row) => [row.name],
      sortable: true,
      cell: (row) => (
        <div>
          <div style={{ cursor: "pointer" }} data-tag="allowRowEvents">
            {row.name}
          </div>
          {row.summary}
        </div>
      ),
      // minWidth: "8rem",
    },

    {
      name: "Phone Nr",
      selector: (row) => [row.phone],
      cell: (row) => (
        <div>
          <div>{row.phone}</div>
          {row.summary}
        </div>
      ),
      // maxWidth: "9rem",
    },

    {
      name: "Email",
      selector: (row) => [row.email],
      cell: (row) => (
        <div>
          <div>{row.email}</div>
          {row.summary}
        </div>
      ),
      minWidth: "8rem",
    },
    {
      name: "Languages",
      selector: (row) => [row.language],
      cell: (row) => (
        <div>
          <div>{row.language}</div>
        </div>
      ),
      // minWidth: "6rem",
    },
    {
      name: "Role",
      selector: (row) => [row.role],
      cell: (row) => (
        <div>
          <div>{row.role}</div>
          {row.summary}
        </div>
      ),
    },
    {
      name: "Recruiter",
      selector: (row) => [row.recruiter],
      cell: (row) => (
        <div>
          <div>{row.recruiter}</div>
          {row.summary}
        </div>
      ),
      // maxWidth: "10rem",
    },

    {
      name: "Status",
      selector: (row) => [row.status],
      cell: (row) => (
        <div>
          <div>{filterStatuses(Number(row.status))}</div>
          {row.summary}
        </div>
      ),
      // maxWidth: "10rem",
    },
    {
      name: "Created At",
      selector: (row) => [row.createdAt],
      cell: (row) => (
        <div>
          <div>
            {row.createdAt ? new Date(row.createdAt).toLocaleString() : null}
          </div>
          {row.summary}
        </div>
      ),
      // maxWidth: "10rem",
    },

    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <>
          <Link
            to={{ pathname: `/candidate/${row.id}`, search: urlParams }}
            data={{ id: row.id }}
            className="view"
          >
            View
          </Link>
        </>
      ),
      width: "4rem",
    },
  ];
  let history = useHistory();
  const onRowClick = (row) => {
    history.push({
      pathname: `/candidate/${row.id}`,
      data: row.id,
      search: urlParams,
    });
  };

  if (statusesReady) {
    return (
      <Loader
        type="ThreeDots"
        color="#1b2330"
        height={100}
        width={100}
        timeout={4000}
      />
    );
  }

  return (
    <div className="filter-wrapper">
      <div className="table">
        <DataTable
          title="Candidates"
          columns={columns}
          data={data}
          defaultSortFieldId={1}
          sortServer
          onSort={handleSort}
          striped
          subHeader
          subHeaderComponent={subheader}
          customStyles={customStyleTable}
          responsive
          paginationTotalRows={totalRows}
          progressPending={loading}
          pagination
          paginationServer
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={onRowClick}
          progressComponent={
            <Loader
              type="ThreeDots"
              color="#1b2330"
              height={100}
              width={100}
              timeout={4000}
            />
          }
        />
      </div>
    </div>
  );
};

export default TableCandidates;
