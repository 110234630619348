import React, { useState } from "react";
import * as Icons from "react-icons/md";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import userLogo from "../images/user-logo.jpg";
import "../styles/toolbar.css";
import { Logout } from "../helpers/logout";

const Toolbar = ({ sidebarClose, toggleSidebar }) => {
  const cookie = new Cookies();
  const [dropdown, setDropdown] = useState(false);

  const showMenu = () => {
    setDropdown(true);
  };
  const hideMenu = (event) => {
    if (!(event.target && event.target.classList.contains("toolbar-avatar"))) {
      setDropdown(false);
    }
  };
  document.addEventListener("click", hideMenu);
  const user = cookie.get("USERDATA");

  // const Logout = async () => {
  //   await axios
  //     .get(`${process.env.REACT_APP_DEV_API_ROUTE}/auth/logout`)
  //     .then(() => {
  //       cookie.remove("currentUser");
  //       window.location = "/login";
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    <div className="wrapper-toolbar">
      <div className="main-page flex-column">
        <header>
          <Icons.MdMenu
            className={`${sidebarClose ? "menu-icon-toolbar" : "close"}`}
            onClick={toggleSidebar}
          />
          <nav className="toolbar">
            <ul className="toolbar-links flex-end">
              <li className="toolbar-link flex-end">
                <Link to="/notifications" title="Notifications">
                  <Icons.MdNotifications className="icon-item-toolbar" />
                </Link>
              </li>
            </ul>
          </nav>
          <div className="avatar flex-column flex-end">
            <p className="avatar-name">
              <b>
                {user.firstName} {user.lastName}
              </b>
            </p>
            <p className="avatar-role">
              {user.email} {user.roles[0].slice(5)}
            </p>
          </div>
          <div className="toolbar-logo flex-end">
            <img
              src={userLogo}
              alt="logo"
              className="toolbar-avatar"
              onClick={showMenu}
            />

            <div className={`${dropdown ? "dropdown" : "close"}`}>
              <ul className="dropdown-content">
                <li className="dropdown-item" onClick={(e) => Logout()}>
                  <Icons.MdExitToApp className="icon-item-dropdown" />
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Toolbar;
