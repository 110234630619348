import React from "react";
import axios from "axios";
import { BiShowAlt } from "react-icons/bi";
import "../App.css";
import "../styles/Login.css";
import logo from "../images/fuse.svg";
// import Cookies from "universal-cookie";
import { withRouter } from "react-router";
// import { Logout } from "../helpers/logout";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: {
        isValid: false,
        message: null,
        class: null,
        value: null,
      },
      password: {
        isValid: false,
        message: null,
        class: null,
        value: null,
      },
      showPassword: false,
      errorMessage: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  isValidEmail(event) {
    const email = event.target.value;
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(email)) {
      this.setState((prevState) => ({
        ...prevState,
        email: {
          isValid: true,
          message: "",
          class: "succes",
          value: event.target.value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        email: {
          isValid: false,
          message: "Please insert a valid email",
          class: "error",
        },
      }));
    }
  }

  isValidPassword(event) {
    const password = event.target.value;
    if (!password.length)
      this.setState((prevState) => ({
        ...prevState,
        password: {
          isValid: false,
          message: "Please enter your password",
          class: "error",
        },
      }));
    else if (password.length < 8)
      this.setState((prevState) => ({
        ...prevState,
        password: {
          isValid: false,
          message: "Password is too short - should be 8 chars min",
          class: "error",
        },
      }));
    else
      this.setState((prevState) => ({
        ...prevState,
        password: {
          isValid: true,
          message: "",
          class: "succes",
          value: event.target.value,
        },
        errorMessage: null,
      }));
  }

  // async getCurrentUser() {
  //   await axios
  //     .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/user/current-user`)
  //     .then((res) => {
  //       const cookie = new Cookies();
  //       cookie.set("currentUser", res?.data, {
  //         path: "/",
  //         maxAge: 86400,
  //       });
  //       window.location.href = "/";
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       Logout();
  //     });
  // }

  async onSubmit(event) {
    event.preventDefault();
    await axios
      .post(`${process.env.REACT_APP_DEV_API_ROUTE}/auth/login`, {
        username: this.state.email.value,
        password: this.state.password.value,
      })
      .then(() => {
        // this.getCurrentUser();
        window.location = "/";
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response?.data?.results) {
          console.log(err.response.data);
          err.response?.data?.results.forEach((error) => {
            document.getElementById(error.field).innerHTML = error.message;
          });
        } else if (err.response?.status === 404) {
          this.setState({ errorMessage: "404 NoT Found" });
        } else if (err?.response?.status === 401) {
          this.setState({ errorMessage: "Unauthorized" });
        } else {
          this.setState({ errorMessage: err.response?.data?.message });
        }
      });
  }

  seePassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  render() {
    return (
      <div className="wrapper">
        <div className="container">
          <div className="logo-wrapper">
            <a href="/" title="Home">
              <img src={logo} className="logo" alt="Logo" />
            </a>
          </div>

          <div className="presentation-wrapper text-center">
            <h2 className="presentation">Login to your account</h2>
          </div>
          <div className="form-wrapper">
            <form className="form" onSubmit={this.onSubmit}>
              <div className="input-wrapper">
                <input
                  className={`input ${this.state.email.class}`}
                  type="text"
                  placeholder="Email adress"
                  onChange={this.isValidEmail.bind(this)}
                />
                <div className="message">
                  <p id="email" className="message__input">
                    {this.state.email.message}
                  </p>
                </div>
              </div>
              <div className="input-wrapper">
                <input
                  className={`input login-margin-right ${this.state.password.class}`}
                  type={this.state.showPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={this.isValidPassword.bind(this)}
                />
                <BiShowAlt
                  className={`login-show-pswd__icon ${
                    this.state.showPassword ? "green" : ""
                  }`}
                  onClick={this.seePassword.bind(this)}
                />
                <div className="message">
                  <p id="password" className="message__input">
                    {this.state.password.message || this.state.errorMessage}
                  </p>
                </div>
              </div>
              <div className="forgot text-center">
                {/* <a href="/forgot-password">Forgot password?</a> */}
              </div>
              <div className="button-wrapper text-center">
                <button
                  className={`button ${
                    this.state.email.isValid && this.state.password.isValid
                      ? ""
                      : "disabled"
                  }`}
                  type="submit"
                  disabled={
                    !(this.state.email.isValid && this.state.password.isValid)
                  }
                >
                  Login
                </button>
              </div>
            </form>
          </div>
          <div className="or-wrapper text-center"></div>
          <div className="no-account text-center"></div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
