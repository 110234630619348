import React from "react";
import { Link } from "react-router-dom";
import userLogo from "../images/user-logo.jpg";
import * as Icons from "react-icons/md";
import "../styles/sidebar.css";
import { Logout } from "../helpers/logout";

const Sidebar = (props) => {
  const { sidebarClose, toggleSidebar, user } = props;

  const path = window.location.pathname;

  return (
    <>
      <div
        className={` ${
          sidebarClose
            ? " wrapper-sidebar"
            : "wrapper-sidebar sidebar-displayed"
        }`}
      >
        <div className="container-sidebar flex-column">
          <div className="x-icon flex-end">
            {/* <img
              alt="logo"
              style={{ width: "8rem", height: "3.5rem", marginLeft: "1rem" }}
              src="https://www.pragmaticplay.com/wp-content/themes/gp-theme-basic/libs/dist/images/PP-white-logo.svg"
            /> */}
            <Icons.MdClose className="menu-bars" onClick={toggleSidebar} />
          </div>
          <div className="header-nav flex-column">
            <p className="user-name" placeholder="UserName">
              {user.firstName.length > 10
                ? user.firstName.slice(0, 9) + "..."
                : user.firstName}{" "}
              {/* {user.lastName.length > 10
                ? user.lastName.slice(0, 7) + "..."
                : user.lastName} */}
            </p>
            <p className="user-mail">
              {user.email.length > 22
                ? user.email.slice(0, 20) + "..."
                : user.email}
            </p>
            <div className="wrapper-logo">
              <Link to="/">
                <img src={userLogo} alt="logo" className="logo-sidebar" />
              </Link>
            </div>
          </div>
          <div className="body-nav">
            <nav className="main-menu flex-column">
              <ul>
                <div className="users-span users-span-first">
                  <span className="subheader-text">USERS</span>
                </div>
                <Link title="Users" to="/users" className="nav-items">
                  <li
                    className={`${
                      path === "/users"
                        ? "active"
                        : path === `/user/:id`
                        ? "active"
                        : ""
                    }`}
                  >
                    <Icons.MdPerson className="icon-item" />
                    <span className="nav-text">All Users</span>
                  </li>
                </Link>
                {user.roles[0] === "ROLE_ADMIN" && (
                  <Link title="Users" to="/create-user" className="nav-items">
                    <li
                      className={`${path === "/create-user" ? "active" : ""}`}
                    >
                      <Icons.MdPersonAdd className="icon-item" />
                      <span className="nav-text">Create New Users</span>
                    </li>
                  </Link>
                )}
                <div className="users-span">
                  <span className="subheader-text">CANDIDATES</span>
                </div>

                <Link
                  title="allcandidates"
                  to="/all-candidates"
                  className="nav-items"
                >
                  <li
                    className={`${
                      path === "/all-candidates" || path === "/candidate/:id"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Icons.MdPersonPin className="icon-item" />
                    <span className="nav-text">All Candidates</span>
                  </li>
                </Link>
                <Link
                  title="candidates"
                  to="/create-candidate"
                  className="nav-items"
                >
                  <li
                    className={`${
                      path === "/create-candidate" ? "active" : ""
                    }`}
                  >
                    <Icons.MdPersonAdd className="icon-item" />
                    <span className="nav-text">Add Candidate</span>
                  </li>
                </Link>
                <Link title="sharepoint" to="/sharepoint" className="nav-items">
                  <li className={`${path === "/sharepoint" ? "active" : ""}`}>
                    <Icons.MdLink className="icon-item" />
                    <span className="nav-text">Sharepoint</span>
                  </li>
                </Link>
              </ul>
            </nav>
            <div className="logout-sidebar" onClick={(e) => Logout()}>
              <Icons.MdPowerSettingsNew className="logout-icon" />
              <span className="logout-span">Logout</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
