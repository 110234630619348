import React from "react";
import "./users.css";

const SubheaderTable = ({
  setFirstName,
  setLastName,
  setUserEmail,
  setUserStatus,
  userStatus,
  handleFilter,
}) => {
  const filterOptions = ["First Name", "Last Name", "Email"];

  return (
    <div className="wrapper-subheader ">
      <form className="subheader-form">
        {filterOptions.map((option) => {
          return (
            <input
              key={option}
              type="text"
              placeholder={`Filter by ${option}`}
              className="search-box"
              onChange={(e) => {
                option === "First Name"
                  ? setFirstName(e.target.value)
                  : option === "Last Name"
                  ? setLastName(e.target.value)
                  : setUserEmail(e.target.value);
              }}
            />
          );
        })}

        <div key="btn" className="btn-subheader">
          <button
            type="submit"
            className="btn-action filter"
            onClick={handleFilter}
          >
            Filter
          </button>
        </div>
        <div className="checkbox-container">
          <label className="checkbox-label">
            <input
              type="checkbox"
              id="deleted-users"
              className="checkbox"
              onChange={(e) => {
                setUserStatus(userStatus === 1 ? null : 1);
              }}
            />
            <strong>Display deleted Users</strong>
          </label>
        </div>
      </form>
    </div>
  );
};

export default SubheaderTable;
