//esentials
import React from "react";
import styles from "./candidates.module.css";
import Cookies from "universal-cookie";
//icons
import * as Icons from "react-icons/md";
import { IoCloudUploadOutline, IoCloudDoneOutline } from "react-icons/io5";
import { BiDollar } from "react-icons/bi";
import { GoLocation } from "react-icons/go";
import { FaCommentDots } from "react-icons/fa";
//helpers
import {
  optionsLanguages,
  optionsInterview,
  optionsFeedback,
} from "./dropdownOptions";
import { fieldsTexarea } from "./inputsOptions";
//components
import InputText from "./components/InputText";
import InputSelect from "./components/InputSelect";

///
const CandidateForm = ({
  handleSubmit,
  changeHandler,
  selectedFile,
  isFilePicked,
  statuses,
  sources,
  candidate,
  setCandidate,
}) => {
  const cookie = new Cookies();
  const user = cookie.get("USERDATA");

  const onStatusChange = (selected) => {
    // setStatus(selected.id);
    setCandidate((candidate) => ({ ...candidate, status: selected.id }));
  };
  const onLanguageChange = (selected) => {
    let languages = "";
    selected.forEach((select) => {
      languages += select.id + ";";
    });
    // setLanguages(languages);
    setCandidate((candidate) => ({ ...candidate, language: languages }));
  };
  const onSourceChange = (selected) => {
    // setSource(selected.id);
    setCandidate((candidate) => ({ ...candidate, source: selected.id }));
  };
  const onInterviewChange = (selected) => {
    // setInterviewType(selected.value);
    setCandidate((candidate) => ({
      ...candidate,
      interviewType: selected.id,
    }));
  };
  const onFeedbackLanguageChange = (selected) => {
    // setFeedbackLanguage(selected.value);
    setCandidate((candidate) => ({
      ...candidate,
      feedbackLanguage: selected.id,
    }));
  };

  const multiSelectPopulate = (options, values) => {
    if (!values) {
      return [];
    }
    let valuesArray = values.split(";");
    let selectOptions = [];
    options.forEach((option) => {
      if (valuesArray.includes(option.id)) {
        selectOptions.push(option);
      }
    });

    return selectOptions;
  };

  const formFields = [
    { name: "name", type: "text", title: "Name", restricted: false },
    { name: "phone", type: "text", title: "Phone Number", restricted: false },
    { name: "email", type: "text", title: "Email", restricted: false },
    { name: "recruiter", type: "text", title: "Recruiter", restricted: false },

    {
      name: "status",
      type: "select",
      title: "Status",
      options: statuses,
      onChange: onStatusChange,
      defaultValue: statuses.filter((option) => option.id === candidate.status),
    },

    {
      name: "statusDetails",
      type: "text",
      title: "Status Details",
      restricted: false,
    },
    {
      name: "source",
      type: "select",
      title: "Source",
      options: sources,
      onChange: onSourceChange,
      defaultValue: sources.filter((option) => option.id === candidate.source),
    },
    {
      name: "sourceDetails",
      type: "text",
      title: "Source Details",
      restricted: false,
    },
    { name: "project", type: "text", title: "Project", restricted: false },

    {
      name: "language",
      type: "select",
      title: "Languages",
      options: optionsLanguages,
      onChange: onLanguageChange,
      defaultValue: multiSelectPopulate(optionsLanguages, candidate.language),
    },
    {
      name: "feedbackLanguage",
      type: "select",
      title: "Feedback Language",
      options: optionsFeedback,
      onChange: onFeedbackLanguageChange,
      defaultValue: optionsFeedback.filter(
        (option) => option.id === candidate.feedbackLanguage
      ),
    },
    {
      name: "interviewType",
      type: "select",
      title: "Interview Type",
      options: optionsInterview,
      onChange: onInterviewChange,
      defaultValue: optionsInterview.filter(
        (option) => option.id === candidate.interviewType
      ),
    },
    { name: "location", type: "text", title: "Location", restricted: false },
    { name: "role", type: "text", title: "Role", restricted: false },
    {
      name: "contactedDate",
      type: "date",
      title: "Contacted Date",
      restricted: false,
    },

    {
      name: "interviewDate",
      type: "datetime-local",
      title: "Interview Date",
      restricted: false,
    },
    {
      name: "dateOfJoining",
      type: "date",
      title: "Date of Joining",
      restricted: false,
    },
    {
      name: "lastDay",
      type: "date",
      title: "Last Day",
      restricted: false,
    },
    {
      name: "salaryExpectations",
      type: "text",
      title: "Salary Expectations",
      restricted: user.roles[0] === "ROLE_ADMIN" ? false : true,
    },
  ];

  const setField = (name, value) => {
    setCandidate((candidate) => ({ ...candidate, [name]: value }));
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.text_center}>Create Candidate's Profile</h1>
      </header>
      <form className={styles.form}>
        <div className={styles.container_fluid} style={{ flexWrap: "wrap" }}>
          {formFields.map((option) => {
            return option.type === "select" ? (
              <div key={option.name} className={styles.input_wrapper}>
                <label className={styles.label}>
                  {option.name === "language" ||
                  option.name === "feedbackLanguage" ? (
                    <Icons.MdLanguage className={styles.icon} />
                  ) : (
                    <Icons.MdAssignment className={styles.icon} />
                  )}
                  {option.title}
                </label>
                <InputSelect
                  id={option.name}
                  className={styles.input_field}
                  options={option.options}
                  isMulti={option.name === "language" ? true : false}
                  defaultValue={option.defaultValue}
                  onChange={option.onChange}
                />
                <p
                  id={"error-" + option.name}
                  className={styles.error_validation}
                ></p>
              </div>
            ) : (
              option.restricted === false && (
                <div key={option.name} className={styles.input_wrapper}>
                  <label className={styles.label}>
                    {option.name === "name" ? (
                      <Icons.MdPerson className={styles.icon} />
                    ) : option.name === "email" ? (
                      <Icons.MdEmail className={styles.icon} />
                    ) : option.name === "salaryExpectations" ? (
                      <BiDollar className={styles.icon} />
                    ) : option.type === "date" ||
                      option.type === "datetime-local" ? (
                      <Icons.MdToday className={styles.icon} />
                    ) : option.name === "location" ? (
                      <GoLocation className={styles.icon} />
                    ) : option.name === "phone" ? (
                      <Icons.MdPhone className={styles.icon} />
                    ) : (
                      <Icons.MdAssignment className={styles.icon} />
                    )}
                    {option.title}
                  </label>
                  <InputText
                    id={option.name}
                    className={styles.input_field}
                    type={option.type}
                    onChange={(e) => setField(option.name, e.target.value)}
                    defaultValue={candidate[option.name]}
                  />
                  <p
                    id={"error-" + option.name}
                    className={styles.error_validation}
                  ></p>
                </div>
              )
            );
          })}
        </div>
        <div className={styles.container_fluid}>
          {fieldsTexarea.map((option) => {
            return (
              <div key={option.name} className={styles.input_wrapper}>
                <label className={styles.label}>
                  <FaCommentDots className={styles.icon} />
                  {option.title}
                </label>
                <textarea
                  className={styles.input_field}
                  id={option.name}
                  style={{ height: "6rem" }}
                  onChange={(e) => setField(option.name, e.target.value)}
                  defaultValue={candidate[option.name]}
                />
                <p
                  id={"error-" + option.name}
                  className={styles.error_validation}
                ></p>
              </div>
            );
          })}
        </div>
        <div className={styles.container_fluid_upload}>
          <label
            id="file"
            htmlFor="file-upload"
            className={styles.upload_label}
          >
            {!isFilePicked ? (
              <IoCloudUploadOutline className={styles.icon} />
            ) : (
              <IoCloudDoneOutline className={styles.icon} />
            )}
            Upload File
          </label>
          <input
            encType="multipart/form-data"
            id="file-upload"
            type="file"
            onChange={changeHandler}
            accept=".pdf, .jpg, .jpeg, .doc, .docx, .png"
          />

          <p className={styles.upload_file__name}>
            {isFilePicked && selectedFile
              ? selectedFile.name
              : "No file chosen"}
          </p>
        </div>
        <p id="error-file" className={styles.error_upload}></p>

        <div className={styles.btn_wrapper}>
          <button
            title="To activate, enter a name and a valid email"
            className={styles.btn}
            onClick={handleSubmit}
          >
            Create Profile
          </button>
        </div>
      </form>
    </div>
  );
};

export default CandidateForm;
