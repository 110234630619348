import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import Table from "./DataTable";
import DeleteModal from "./DeleteModal";
import SubheaderTable from "./SubheaderTable";
import { Logout } from "../../helpers/logout";

Modal.setAppElement("#root");

const Users = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [first_name, setFirst_name] = useState(null);
  const [last_name, setLast_name] = useState(null);
  const [user_email, setUser_email] = useState(null);
  const [user_status, setUser_status] = useState(1);
  const [sortFirstNameDirection, setSortFirstNameDirection] = useState("ASC");
  const [sortLastNameDirection, setSortLastNameDirection] = useState(null);
  const [fetch, setFetch] = useState(false);

  const params = {
    firstName: first_name,
    lastName: last_name,
    email: user_email,
    status: user_status,
    "sort[firstName]": sortFirstNameDirection,
    "sort[lastName]": sortLastNameDirection,
  };

  //fetch users function
  const getUsers = async (page) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/users`, {
        params: {
          page: page,
          per_page: perPage,
          ...params,
        },
      })
      .then((res) => {
        setResults(res?.data?.results);
        setTotalRows(res?.data?.totalResults);
        setPage(page);
        setLoading(false);
      })
      .catch((err) => {
        if (
          err.response?.data?.code === 403 ||
          err.response?.data?.code === 401
        ) {
          Logout();
        }
        console.log(err);
      });
  };

  /// change table page  function
  const handlePageChange = (page) => {
    getUsers(page);
  };

  ///filter function
  const handleFilter = async (e) => {
    e.preventDefault();
    await axios
      .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/users`, {
        params: { page: page, per_page: perPage, ...params },
      })
      .then((res) => {
        setResults(res?.data?.results);
        setTotalRows(res?.data?.totalResults);
        setPage(page);
        setLoading(false);
      })
      .catch((err) => {
        if (
          err.response?.data?.code === 403 ||
          err.response?.data?.code === 401
        ) {
          Logout();
        }
        console.log(err);
      });
  };

  const handleSort = (column, sortDirection) => {
    if (column.name === "First Name" && sortDirection === "asc") {
      setSortFirstNameDirection("ASC");
      setSortLastNameDirection(null);
    } else if (column.name === "First Name" && sortDirection === "desc") {
      setSortFirstNameDirection("DESC");
      setSortLastNameDirection(null);
    } else if (column.name === "Last Name" && sortDirection === "asc") {
      setSortLastNameDirection("ASC");
      setSortFirstNameDirection(null);
    } else if (column.name === "Last Name" && sortDirection === "desc") {
      setSortLastNameDirection("DESC");
      setSortFirstNameDirection(null);
    }
  };

  /// change number of rows per page
  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(
      `${process.env.REACT_APP_DEV_API_ROUTE}/api/users`,
      {
        params: { page: page, ...params },
      }
    );
    setResults(response?.data?.results);
    setPerPage(newPerPage);
    setLoading(false);
  };

  //fetch first page of users
  useEffect(() => {
    getUsers(page);
    // eslint-disable-next-line
  }, [
    user_status,
    sortFirstNameDirection,
    sortLastNameDirection,
    perPage,
    fetch,
  ]);

  // Modal confirmation
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const setModalOpen = (row) => {
    setIsOpen(true);
    setSelectedRow(row);
  };

  const setModalClose = () => {
    setIsOpen(false);
    setFetch(!fetch);
  };

  // confirm delete

  const deleteHandler = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_DEV_API_ROUTE}/api/user/${selectedRow.id}/change-status`,
        {
          body: {
            status: false,
          },
        }
      )
      .then(() => {
        setModalClose(true);
      })
      .catch((err) => {
        if (
          err.response?.data?.status === 403 ||
          err.response?.data?.status === 401
        ) {
          Logout();
        }
        console.log(err);
      });
  };

  return (
    <div>
      <Table
        data={results}
        click={setModalOpen}
        loading={loading}
        totalRows={totalRows}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        handleSort={handleSort}
        subheader={
          <SubheaderTable
            setFirstName={setFirst_name}
            setLastName={setLast_name}
            setUserEmail={setUser_email}
            setUserStatus={setUser_status}
            userStatus={user_status}
            handleFilter={handleFilter}
          />
        }
      />
      <Modal
        isOpen={isOpen}
        onRequestClose={setModalClose}
        overlayClassName="Overlay"
        className="Modal"
      >
        <DeleteModal
          selectedRow={selectedRow}
          setModalClose={setModalClose}
          deleteHandler={deleteHandler}
        />
      </Modal>
    </div>
  );
};

export default Users;
