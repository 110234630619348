import axios from "axios";
import Cookies from "universal-cookie";

export const Logout = async () => {
  const cookie = new Cookies();
  await axios
    .get(`${process.env.REACT_APP_DEV_API_ROUTE}/auth/logout`)
    .then(() => {
      cookie.remove("USERDATA");
      window.location = "/login";
    })
    .catch((err) => console.log(err));
};
