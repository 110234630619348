import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { customStyle } from "./customStyle";
import Loader from "react-loader-spinner";
import Cookies from "universal-cookie";

const Table = ({
  click,
  data,
  loading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  subheader,
  handleSort,
}) => {
  const cookie = new Cookies();
  const user = cookie.get("USERDATA");

  const columns = [
    {
      name: "First Name",
      selector: (row) => [row.firstName],
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => [row.lastName],
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => [row.email],
    },
    {
      name: "Role",
      selector: (row) => [row.roles[0].slice(5)],
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <div
          className={`${
            user.roles[0] === "ROLE_ADMIN" ? "actions" : "actions-disabled"
          }`}
        >
          <Link to={`/user/${row.id}`} data={{ id: row.id }} className="view">
            View
          </Link>
          {row.email !== user.email && (
            <button
              id={row.id}
              className={`btn-action ${
                row.status === true ? "delete" : "restore"
              }`}
              onClick={() => click(row)}
            >
              {`${row.status === true ? "Delete" : "Restore"}`}
            </button>
          )}
        </div>
      ),
      minWidth: "10.5rem",
    },
  ];

  return (
    <div className="filter-wrapper">
      <div className="table">
        <DataTable
          title="Users List"
          columns={columns}
          data={data}
          defaultSortFieldId={1}
          sortServer
          onSort={handleSort}
          responsive
          striped
          subHeader
          subHeaderComponent={subheader}
          customStyles={customStyle}
          pagination
          paginationServer
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressPending={loading}
          progressComponent={
            <Loader
              type="ThreeDots"
              color="#1b2330"
              height={100}
              width={100}
              timeout={4000}
            />
          }
          paginationTotalRows={totalRows}
        />
      </div>
    </div>
  );
};

export default Table;
