import React, { useState, useEffect } from "react";
import "./users.css";

import Loader from "react-loader-spinner";
import axios from "axios";
import UserForm from "./UserForm";
import NotFound from "../NotFound";
import { Logout } from "../../helpers/logout";

const CreateUser = (props) => {
  const path = window.location.pathname;
  const id = Number(props?.match?.params?.id);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (path === `/user/${id}`) {
      const getUser = async () => {
        await axios
          .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/user/${id}`)
          .then((res) => {
            setUser(res?.data?.results[0]);
            setLoading(false);
          })
          .catch((err) => {
            if (err.response?.data?.status === 404) {
              setNotFound(true);
            } else if (
              err.response?.data?.status === 401 ||
              err.response?.data?.status === 403
            ) {
              Logout().then(() => props.history.push("/login"));
            } else {
              console.log(err.response);
            }
          });
      };
      getUser();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  if (loading && !notFound) {
    return (
      <div className="spinner">
        <Loader
          type="ThreeDots"
          color="#1b2330"
          height={100}
          width={100}
          timeout={4000}
        />
      </div>
    );
  }

  if (notFound) {
    return <NotFound />;
  }

  return <UserForm user={user} loading={loading} id={id} />;
};

export default CreateUser;
