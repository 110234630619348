import React from "react";

const Home = () => {
  return (
    <div className="wellcome-div">
      <h1 className="wellcome-header">Welcome!</h1>
      {/* <h2 className="wellcome-subheader">You can now do your best!</h2> */}
    </div>
  );
};

export default Home;
