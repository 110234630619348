export const optionsLanguages = [
  { id: "Arabic", name: "Arabic" },
  { id: "Asian", name: "Asian" },
  { id: "Bulgarian", name: "Bulgarian" },
  { id: "Chinese", name: "Chinese" },
  { id: "Deutsch", name: "Deutsch" },
  { id: "Dutch", name: "Dutch" },
  { id: "English", name: "English" },
  { id: "French", name: "French" },
  { id: "Hindi", name: "Hindi" },
  { id: "Italian", name: "Italian" },
  { id: "Korean", name: "Korean" },
  { id: "Romanian", name: "Romanian" },
  { id: "Spanish", name: "Spanish" },
  { id: "Russian", name: "Russian" },
  { id: "Turkish", name: "Turkish" },
];

export const optionsInterview = [
  { id: 1, name: "Office Live" },
  { id: 2, name: "Office SRL" },
  { id: 3, name: "Skype " },
  { id: 4, name: "Teams " },
  { id: 5, name: "TBD " },
];

export const optionsRole = [
  { value: "ROLE_ADMIN", label: "Admin" },
  { value: "ROLE_MODERATOR", label: "Moderator" },
];

export const optionsFeedback = [
  { id: 1, name: "Romana" },
  { id: 2, name: "Engleza" },
];
