import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "./candidates.module.css";

import { Logout } from "../../helpers/logout";
import { store } from "react-notifications-component";
import {
  notificationError,
  notificationSucces,
} from "../../helpers/notifications";

import Loader from "react-loader-spinner";
import CandidateForm from "./CandidateForm";

//
const Canditates = () => {
  let lastCandidate = {};

  if (window.localStorage.getItem("last-candidate")) {
    lastCandidate = JSON.parse(window.localStorage.getItem("last-candidate"));
  }

  //candidate informations
  const [candidate, setCandidate] = useState({
    name: null,
    email: null,
    contactedDate: lastCandidate.contactedDate,
    phone: null,
    comments: lastCandidate.comments,
    project: lastCandidate.project,
    language: lastCandidate.language,
    salaryExpectations: lastCandidate.salaryExpectations,
    interviewDate: lastCandidate.interviewDate,
    status: lastCandidate.status,
    source: lastCandidate.source,
    recruiter: lastCandidate.recruiter,
    dateOfJoining: lastCandidate.dateOfJoining,
    feedback: lastCandidate.feedback,
    interviewType: lastCandidate.interviewType,
    keywords: lastCandidate.keywords,
    feedbackLanguage: lastCandidate.feedbackLanguage,
    role: lastCandidate.role,
    statusDetails: lastCandidate.statusDetails,
    sourceDetails: lastCandidate.sourceDetails,
    location: lastCandidate.location,
    lastDay: null,
  });

  const [sourcesReady, setSourcesReady] = useState(true);
  const [loading, setLoading] = useState(true);

  // file upload
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);

  // statuses & sources
  const [statuses, setStatuses] = useState({});
  const [statusesReady, setStatusesReady] = useState(true);
  const [sources, setSources] = useState({});

  useEffect(() => {
    const getStatuses = async () => {
      await axios
        .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/statuses`)
        .then((res) => {
          setStatuses(
            res?.data?.results.sort((a, b) =>
              a.orderId > b.orderId ? 1 : b.orderId > a.orderId ? -1 : 0
            )
          );
          setLoading(false);
          setStatusesReady(false);
        })
        .catch((err) => console.log(err));
    };
    const getSources = async () => {
      await axios
        .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/sources`)
        .then((res) => {
          setSources(
            res?.data?.results.sort((a, b) =>
              a.orderId > b.orderId ? 1 : b.orderId > a.orderId ? -1 : 0
            )
          );
          setSourcesReady(false);
        })
        .catch((err) => console.log(err));
    };
    getSources();
    getStatuses();
  }, []);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  let history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(`${process.env.REACT_APP_DEV_API_ROUTE}/api/candidate`, {
        ...candidate,
      })
      .then(async (res) => {
        const id = res?.data?.results[0]?.id;
        if (isFilePicked && selectedFile) {
          const formdata = new FormData();
          formdata.append("file", selectedFile, selectedFile.name);
          formdata.append("type", "1");
          formdata.append("candidate_id", id);
          await axios
            .post(
              `${process.env.REACT_APP_DEV_API_ROUTE}/api/candidate_file`,
              formdata
            )
            .then(() => {
              notificationSucces.message = "Candidate successfully created!";
              store.addNotification(notificationSucces);
              history.push("/all-candidates");
            })
            .catch((err) => {
              if (err?.response?.data?.results) {
                notificationError.message =
                  "You'll be redirected to candidate's page to fix the error!  " +
                  err?.response?.data?.results[0].message;
                notificationError.dismiss.duration = 7000;
                store.addNotification(notificationError);
                document.getElementById(
                  "error-" + err?.response?.data?.results[0]?.field
                ).innerHTML = err?.response?.data?.results[0]?.message;
                setTimeout(() => {
                  history.push({ pathname: `/candidate/${id}`, data: id });
                }, 2500);
              }
            });
        } else if (!isFilePicked && !selectedFile) {
          notificationSucces.message = "Candidate successfully created!";
          store.addNotification(notificationSucces);
          window.localStorage.setItem(
            "last-candidate",
            JSON.stringify(candidate)
          );
          history.push("/all-candidates");
        }
      })
      .catch((err) => {
        if (
          err.response?.data?.code === 401 ||
          err.response?.data?.code === 403
        ) {
          Logout();
        }
        if (err.response?.data?.results) {
          err.response.data.results.forEach((error) => {
            document.getElementById(error.field).classList.add(styles.error);
            document.getElementById("error-" + error.field).innerHTML =
              error.message;
          });
          notificationError.message =
            "You have some errors! Please, check input fields!";
          store.addNotification(notificationError);
        } else {
          notificationError.message =
            "Something went wrong... Please try again!";
          store.addNotification(notificationError);
        }
      });
  };

  if (loading || sourcesReady || statusesReady) {
    return (
      <div className="spinner">
        <Loader
          type="ThreeDots"
          color="#1b2330"
          height={100}
          width={100}
          timeout={4000}
        />
      </div>
    );
  }

  return (
    <CandidateForm
      handleSubmit={handleSubmit}
      changeHandler={changeHandler}
      selectedFile={selectedFile}
      isFilePicked={isFilePicked}
      statuses={statuses}
      sources={sources}
      candidate={candidate}
      setCandidate={setCandidate}
    />
  );
};

export default Canditates;
