import React from "react";
import "../App.css";
import "../styles/Login.css";
import logo from "../images/fuse.svg";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: {
        isValid: false,
        message: "",
        class: "",
      },
      password: {
        isValid: false,
        message: "",
        class: "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  isValidEmail(event) {
    const email = event.target.value;
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(email)) {
      this.setState((prevState) => ({
        ...prevState,
        email: {
          isValid: true,
          message: "",
          class: "succes",
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        email: {
          isValid: false,
          message: "Please insert a valid email",
          class: "error",
        },
      }));
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = event.target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onSubmit(event) {
    event.preventDefault();
    localStorage.setItem("isAuthenticated", true);
    window.location.href = "/forgot-password";
  }

  render() {
    return (
      <div className="wrapper">
        <div className="container">
          <div className="logo-wrapper">
            <a href="/" title="Home">
              <img src={logo} className="logo" alt="Logo" />
            </a>
          </div>

          <div className="presentation-wrapper text-center">
            <h2 className="presentation">Recover your password</h2>
          </div>
          <div className="form-wrapper">
            <form className="form" onSubmit={this.onSubmit}>
              <div className="input-wrapper">
                <input
                  className={`input email-field ${this.state.email.class}`}
                  type="text"
                  id="email"
                  placeholder="Email adress"
                  onChange={this.isValidEmail.bind(this)}
                />
                <div
                  className={` message ${
                    this.state.email.isValid === true
                      ? "succes-text"
                      : "error-text"
                  }`}
                >
                  <p>{this.state.email.message}</p>
                </div>
              </div>

              <div className="button-wrapper text-center">
                <button
                  className={`button ${
                    this.state.email.isValid ? "" : "disabled"
                  }`}
                  type="submit"
                  disabled={!this.state.email.isValid}
                >
                  Send reset link
                </button>
              </div>
            </form>
          </div>
          <div className="no-account text-center">
            <a href="/login" title="back to login">
              Go back to Login
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
