import React, { useState, useEffect } from "react";
import "./users.css";
import * as Icons from "react-icons/md";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BiShowAlt } from "react-icons/bi";
import { default as Select } from "react-select";
import { optionsRole } from "../candidates/dropdownOptions";
import { customStylesUserSelect } from "./customStyle";
import { Logout } from "../../helpers/logout";
import NotFound from "../NotFound";

const UserForm = ({ user, id }) => {
  const path = window.location.pathname;

  /// show password
  const [showPassword, setShowPassword] = useState(false);
  const seePassword = () => {
    setShowPassword(!showPassword);
  };

  /// state for input fields
  const [firstName, setFirstName] = useState(
    path === "/create-user" ? "" : user.firstName
  );
  const [lastName, setLastName] = useState(
    path === "/create-user" ? "" : user.lastName
  );
  const [role, setRole] = useState(
    path === "/create-user"
      ? [optionsRole[0]]
      : optionsRole.filter((option) => option.value === user.roles[0])
  );
  const [email, setEmail] = useState(path === "/create-user" ? "" : user.email);

  // state for validate email, password + error messages
  const [password, setPassword] = useState(null);
  const [emailStyle, setEmailStyle] = useState(null);
  const [passwordStyle, setPasswordStyle] = useState(null);
  const [emailMessage, setEmailMessage] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [notFound, setNotFound] = useState(false);

  // validate email function
  const validateEmail = (event) => {
    let email = event.target.value;
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(email)) {
      setIsEmailValid(true);
      setEmailStyle("succes");
      setEmailMessage("");
    } else {
      setEmailStyle("error");
      setEmailMessage("Please enter a valid Email");
      setIsEmailValid(false);
    }
    setEmail(email);
  };

  // validate password function
  const validatePassword = (event) => {
    const password = event.target.value;
    if (!password.length) {
      setPasswordMessage("Please enter your password");
      setPasswordStyle("error");
      setIsPasswordValid(false);
    } else if (password.length < 8) {
      setPasswordMessage("Password is too short - should be 8 chars min");
      setPasswordStyle("error");
      setIsPasswordValid(false);
    } else {
      setPasswordStyle("succes");
      setIsPasswordValid(true);
      setPasswordMessage("");
    }
    setPassword(password);
  };

  let notificationSucces = {
    title: "Succes!",
    message: "",
    type: "success",
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    isMobile: true,
    dismiss: {
      duration: 5000,
      onScreen: true,
      pauseOnHover: true,
    },
  };
  let notificationError = {
    title: "Error!",
    message: "",
    type: "danger",
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    isMobile: true,
    dismiss: {
      duration: 5000,
      onScreen: true,
      pauseOnHover: true,
    },
  };

  /// params for api call
  const params = {};
  if (password) {
    params.password = password;
  }

  /// api call for post or patch

  let history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (path === "/create-user") {
      await axios
        .post(`${process.env.REACT_APP_DEV_API_ROUTE}/api/user`, {
          firstName: firstName,
          lastName: lastName,
          email: email,
          roles: [role[0].value],
          ...params,
        })
        .then(() => {
          notificationSucces.message = "User successfully created!";
          store.addNotification(notificationSucces);
          history.push("/users");
        })
        .catch((err) => {
          if (err.response?.data?.results) {
            err.response.data.results.forEach((error) => {
              document.getElementById(error.field).innerHTML = error.message;
            });
            notificationError.message =
              "You have some errors! Please, check input fields!";
            store.addNotification(notificationError);
          } else if (err.response?.data?.status === 404) {
            setNotFound(true);
          } else if (
            err.response?.data?.status === 401 ||
            err.response?.data?.status === 403
          ) {
            Logout();
          } else {
            console.log(err);
          }
        });
    } else {
      await axios
        .patch(`${process.env.REACT_APP_DEV_API_ROUTE}/api/user/${id}`, {
          firstName: firstName,
          lastName: lastName,
          email: email,

          roles: [role[0].value],
          ...params,
        })
        .then(() => {
          notificationSucces.message = "User successfully updated!";
          store.addNotification(notificationSucces);
          history.push("/users");
        })
        .catch((err) => {
          if (err.response?.data?.results) {
            console.log(err.response.data);
            err.response?.data?.results.forEach((error) => {
              document.getElementById(error.field).innerHTML = error.message;
            });
            notificationError.message =
              "You have some errors! Please, check input fields!";
            store.addNotification(notificationError);
          } else if (err.response?.data?.status === 404) {
            setNotFound(true);
          } else if (
            err.response?.data?.status === 401 ||
            err.response?.data?.status === 403
          ) {
            Logout();
          } else {
            console.log(err);
          }
        });
    }
  };

  /// change role function
  const onRoleChange = (selected) => {
    setRole([selected]);
  };

  /// clear input fileds when create page first renders
  useEffect(() => {
    if (path === "/create-user") {
      setFirstName("");
      setLastName("");
      setEmail("");
      setRole([optionsRole[0]]);
    }
  }, [path]);

  if (notFound) {
    return <NotFound />;
  }

  return (
    <div className="form-container">
      <header>
        <h1>{path === "/create-user" ? "Create a new user" : "Update user"}</h1>
      </header>
      <form onSubmit={handleSubmit} id="user-form" className="form-create-user">
        <div className="input-wrapper-user">
          <label className="label">
            <Icons.MdPerson className="icon-create-user" />
            First Name
          </label>

          <input
            type="text"
            name="firstName"
            onChange={(e) => setFirstName(e.target.value)}
            className="input-create-user"
            value={firstName}
            required
          />
          <div className="validate-message">
            <p id="firstName"></p>
          </div>
        </div>
        <div className="input-wrapper-user">
          <label className="label">
            <Icons.MdPerson className="icon-create-user" />
            Last Name
          </label>

          <input
            type="text"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="input-create-user"
            required
          />
          <div className="validate-message">
            <p id="lastName"></p>
          </div>
        </div>
        <div className="input-wrapper-user">
          <label className="label">
            <Icons.MdAssignment className="icon-create-user" />
            Role
          </label>
          <Select
            type="text"
            name="roles"
            onChange={onRoleChange}
            value={role}
            options={optionsRole}
            styles={customStylesUserSelect}
          />
          <div className="validate-message">
            <p id="roles"></p>
          </div>
        </div>
        <div className="input-wrapper-user">
          <label className="label">
            <Icons.MdEmail className="icon-create-user" />
            Email
          </label>

          <input
            type="text"
            name="email"
            value={email}
            onChange={validateEmail}
            className={`input-create-user ${emailStyle}`}
          />
          <div className="validate-message">
            <p id="email" className="error-validation">
              {emailMessage}
            </p>
          </div>
        </div>
        <label className="label">
          <Icons.MdVpnKey
            style={{ marginLeft: "1rem" }}
            className="icon-create-user"
          />
          Password
        </label>
        <div className="show-pswd">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={validatePassword}
            className={`input-create-user margin-right ${passwordStyle}`}
            placeholder={
              path === "/create-user"
                ? ""
                : "Leave empty not to update the password"
            }
          />
          <BiShowAlt
            className={`show-pswd__icon ${showPassword ? "green" : ""}`}
            onClick={seePassword}
          />
        </div>
        <div className="validate-message">
          <p id="password" className="error-validation">
            {passwordMessage}
          </p>
        </div>
        <button
          disabled={
            path === "/create-user" && !(isEmailValid && isPasswordValid)
          }
          type="submit"
          className={`btn-action create ${
            (path !== "/create-user" && !isEmailValid && !isPasswordValid) ||
            (path === "/create-user" && isEmailValid && isPasswordValid) ||
            (path !== "/create-user" && isPasswordValid) ||
            (path !== "/create-user" && isEmailValid)
              ? ""
              : "disable"
          }`}
        >
          {path === "/create-user" ? "Create User" : "Update User"}
        </button>
      </form>
    </div>
  );
};

export default UserForm;
