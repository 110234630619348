import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Toolbar from "./Toolbar";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "../App.css";

const Layout = (props) => {
  const user = props.user;
  const w = window.innerWidth;
  const [sidebarClose, setSidebarClose] = useState(w > 500 ? false : true);
  const toggleSidebar = () => setSidebarClose(!sidebarClose);
  return (
    <div className="container-app">
      <ReactNotification />
      <div>
        <Sidebar
          sidebarClose={sidebarClose}
          toggleSidebar={toggleSidebar}
          history={props.history}
          user={user}
        />
      </div>
      <div
        className={` component-page ${sidebarClose ? "" : "component-margin"}`}
      >
        <div className="wrapper-toolbar">
          <Toolbar sidebarClose={sidebarClose} toggleSidebar={toggleSidebar} />
        </div>
        <div key={window.location.search} className="container-component">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
