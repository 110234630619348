import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Logout } from "../../helpers/logout";
import Loader from "react-loader-spinner";
import TableCandidates from "./TableCandidates";
import SubheaderCandidates from "./SubheaderCandidates";

const AllCanditates = (props) => {
  const parseParams = (querystring) => {
    // parse query string
    const params = new URLSearchParams(querystring);
    const obj = {};
    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }
    return obj;
  };

  const paramsObj = parseParams(window.location.search);

  const [data, setData] = useState([]);
  //  filtering
  const [perPage, setPerPage] = useState(10);
  // const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    name: paramsObj.name,
    email: paramsObj.email,
    contactedDate: paramsObj.contactedDate,
    phone: paramsObj.phone,
    comments: paramsObj.comments,
    language: paramsObj.language,
    salaryExpectations: paramsObj.salaryExpectations,
    interviewDate: paramsObj.interviewDate,
    status: paramsObj.status,
    source: paramsObj.source,
    project: paramsObj.project,
    recruiter: paramsObj.recruiter,
    dateOfJoining: paramsObj.dateOfJoining,
    feedback: paramsObj.feedback,
    interviewType: paramsObj.interviewType,
    keywords: paramsObj.keywords,
    role: paramsObj.role,
    statusDetails: paramsObj.statusDetails,
    sourceDetails: paramsObj.sourceDetails,
    location: paramsObj.location,
  });

  const [sortId, setSortId] = useState(null);
  const [sortName, setSortName] = useState(null);

  // fetch statuses and sources for filter

  const [sources, setSources] = useState({});
  const [statuses, setStatuses] = useState({});
  const [statusesReady, setStatusesReady] = useState(true);
  const [sourcesReady, setSourcesReady] = useState(true);

  ////
  const [toggleFilters, setToggleFilters] = useState(true);
  ///

  const params = {
    ...filters,
    "sort[id]": sortId,
    "sort[name]": sortName,
  };

  const urlParams = Object.entries(params)
    .map(([key, val]) => (val ? `${key}=${val}&` : null))
    .join("");

  const updateURL = () => {
    history.push(`?${urlParams}`);
  };

  // function to get candidates
  const fetchCandidates = async (page) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/candidates`, {
        params: {
          page: page,
          per_page: perPage,
          ...params,
        },
      })
      .then((res) => {
        setData(res?.data?.results);
        setTotalRows(res?.data?.totalResults);
        // setPage(page);
        setLoading(false);
      })
      .catch((err) => {
        if (
          err.response?.data?.code === 401 ||
          err.response?.data?.code === 403
        ) {
          Logout();
        }
      });
    setLoading(false);
  };
  let history = useHistory();

  //// filter function
  const handleFilter = async (e, page) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/candidates`, {
        params: { page: page, per_page: perPage, ...params },
      })
      .then((res) => {
        setData(res?.data?.results);
        setTotalRows(res?.data?.totalResults);
        // setPage(page);
        setPerPage(perPage);
        setLoading(false);
        updateURL();
      })
      .catch((err) => {
        if (
          err.response?.data?.code === 401 ||
          err.response?.data?.code === 403
        ) {
          Logout();
        }
      });
  };

  const handleSort = (column, sortDirection) => {
    if (column.name === "Name" && sortDirection === "asc") {
      setSortName("ASC");
      setSortId(null);
    } else if (column.name === "Name" && sortDirection === "desc") {
      setSortName("DESC");
      setSortId(null);
    } else if (column.name === "Id" && sortDirection === "asc") {
      setSortId("ASC");
      setSortName(null);
    } else if (column.name === "Id" && sortDirection === "desc") {
      setSortId("DESC");
      setSortName(null);
    }
  };

  useEffect(() => {
    fetchCandidates(1);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchCandidates(1);
    //eslint-disable-next-line
  }, [sortId, sortName]);

  // hook for first render
  useEffect(() => {
    const getSources = async () => {
      await axios
        .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/sources`)
        .then((res) => {
          setSources(
            res?.data?.results.sort((a, b) =>
              a.orderId > b.orderId ? 1 : b.orderId > a.orderId ? -1 : 0
            )
          );
          setSourcesReady(false);
        })
        .catch((err) => console.log(err));
    };
    const getStatuses = async () => {
      await axios
        .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/statuses`)
        .then((res) => {
          setStatuses(
            res?.data?.results.sort((a, b) =>
              a.orderId > b.orderId ? 1 : b.orderId > a.orderId ? -1 : 0
            )
          );
          setStatusesReady(false);
        })
        .catch((err) => console.log(err));
    };
    getSources();
    getStatuses();
    // eslint-disable-next-line
  }, []);

  /// change table page  function
  const handlePageChange = (page) => {
    fetchCandidates(page);
  };

  /// change number of rows per page
  const handlePerRowsChange = async (currentRowsPerPage, page) => {
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_DEV_API_ROUTE}/api/candidates`, {
        params: { page: page, per_page: currentRowsPerPage, ...params },
      })
      .then((res) => {
        setData(res?.data?.results);
        setPerPage(currentRowsPerPage);
        setTotalRows(res?.data?.totalResults);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  if (statusesReady || sourcesReady) {
    return (
      <div className="spinner">
        <Loader
          type="ThreeDots"
          color="#1b2330"
          height={100}
          width={100}
          timeout={4000}
        />
      </div>
    );
  }
  return (
    <div>
      <TableCandidates
        data={data}
        loading={loading}
        totalRows={totalRows}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        handleSort={handleSort}
        handleFilter={handleFilter}
        urlParams={urlParams}
        subheader={
          !(statusesReady && sourcesReady) && (
            <SubheaderCandidates
              handleFilter={handleFilter}
              sources={sources}
              statuses={statuses}
              paramsObj={paramsObj}
              toggleFilters={toggleFilters}
              setToggleFilters={setToggleFilters}
              filters={filters}
              setFilters={setFilters}
            />
          )
        }
      />
    </div>
  );
};

export default AllCanditates;
