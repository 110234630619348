import React from "react";

const Sharepoint = () => {
  return (
    <div>
      <iframe
        title="iframe"
        src="https://docs.google.com/spreadsheets/d/16mRJTxjlETCuAOU0zJZjpsDit0tT3nHRqMm0hV9xbRI/edit#gid=380932195"
        style={{
          width: "100%",
          height: "86vh",
          padding: 0,
          margin: 0,
          border: "none",
        }}
      ></iframe>
    </div>
  );
};

export default Sharepoint;
