export const customStyleTable = {
  table: {
    style: {
      padding: "0 0.5rem",
      zIndex: 1,
    },
  },
  header: {
    style: {
      fontSize: "2rem",
      textAlign: "center",
      color: "white",
      backgroundColor: "#1b2330",
      borderRadius: "6px 6px 0 0",
    },
  },
  subHeader: {
    style: {
      height: "fit-content",
      width: "100%",
      padding: "0",
    },
  },
  headCells: {
    style: {
      lineHeight: "0.2rem",
      fontSize: "1rem",
      padding: "0.9rem",
      minHeight: "1rem",
      textAlign: "center",
    },
  },
  columns: {
    style: {
      width: "3rem",
    },
  },
  rows: {
    style: {
      minHeight: "2.5rem",
      minWidth: "fit-content",
      // cursor: "pointer",
    },
  },
};

export const customStylesSelect = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: "rgb(238, 238, 238)",
    borderRadius: "6px",
    width: "100%",
    margin: 0,
    padding: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: "ellipsis",
    overflowY: "hidden",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  singleValue: (base) => ({
    ...base,
    fontSize: "14px",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "13px",
    color: "rgb(194, 194, 194)",
  }),
};

export const customStylesCreateUpdate = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    margin: " 0 0 0.5rem 0",
    padding: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    borderRadius: "6px",
    borderColor: "rgba(190, 190, 190, 0.911)",
    border: state.isFocused ? "1px" : "",
    padding: 0,
  }),
};
