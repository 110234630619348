import React from "react";
import { default as ReactSelect } from "react-select";

import styles from "../candidates.module.css";
import { customStylesCreateUpdate } from "../customStyle";

const InputSelect = (props) => {
  return (
    <ReactSelect
      {...props}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.name}
      hideSelectedOptions={false}
      className={styles.select}
      styles={customStylesCreateUpdate}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "lightgray",
          primary: "#1b2330",
        },
      })}
    />
  );
};

export default InputSelect;
