import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";

axios.defaults.withCredentials = true;
ReactDOM.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    <Routes />
    {/* </BrowserRouter> */}
  </React.StrictMode>,
  document.getElementById("root")
);
