export let notificationSucces = {
  title: "Succes!",
  message: "",
  type: "success",
  insert: "top",
  container: "bottom-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  isMobile: true,
  dismiss: {
    duration: 5000,
    onScreen: true,
    pauseOnHover: true,
  },
};
export let notificationError = {
  title: "Error!",
  message: "",
  type: "danger",
  insert: "top",
  container: "bottom-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  isMobile: true,
  dismiss: {
    duration: 5000,
    onScreen: true,
    pauseOnHover: true,
  },
};
