import React from "react";
import * as Icons from "react-icons/md";

const DeleteModal = ({ selectedRow, setModalClose, deleteHandler }) => {
  return (
    <div className="Modal">
      <div className="modal-header center-all">
        {selectedRow.status === true ? (
          <Icons.MdWarning className="icon-modal" />
        ) : (
          <Icons.MdCheck className="icon-modal green" />
        )}

        <h4>
          Are you sure you want to{" "}
          {selectedRow.status === true ? " delete" : " restore"}?
        </h4>
      </div>
      <hr className="line" />
      <div className="modal-body center-all">
        <p>
          User <strong> {selectedRow.firstName} </strong> will be
          {selectedRow.status === true ? " deleted!" : " restored!"}
        </p>
      </div>
      <div className="modal-footer center-all">
        <button onClick={setModalClose} className="btn-action view cancel">
          Cancel
        </button>
        <button
          type="button"
          onClick={deleteHandler}
          className={`btn-action ${
            selectedRow.status === true ? " confirm" : "modal-restore"
          }`}
        >
          {selectedRow.status === true ? " Delete" : "Restore"}
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;
